/**
 *
 *    TRANSPORT IN CART
 *    Contains all functions needed to update the shipping costs in the cart
 *
 */


// Update shipping costs and delivery types after choosing another country
// (Used in cart-delivery)
function UpdateTransportOnCountryChange() {
    $('select#selCountry, select#selTransportCountry').change(function () {

        // Update shipping costs
        UpdateShippingCosts('strCountryCode=' + $(this).val());

        // Update delivery types when changing country
        if (WebsiteSettingBlnUseDeliveryTypes) {
            // Get delivery types from the selected country
            $.post(strAjaxPath + 'cart/get-deliverytypes.php', 'strCountryCode=' + $(this).val(), function (objData) {
                // Remove spaces for comparing
                strDataCompare = objData.replace(/\s/g, '');

                // Check if there are delivery types
                if (strDataCompare.substring(0, 3) == '<ul') {
                    // There are delivery types => show them
                    $('div#delivery-types').html(objData);
                    $('div#delivery-types-wrap').removeClass('d-none');
                } else {
                    // No delivery types => hide them
                    $('div#delivery-types').html('');
                    $('div#delivery-types-wrap').addClass('d-none');
                }

                // Update extra shipping costs
                UpdateTransportOnDeliveryTypeChange();
            });
        }
    });
}

// Update extra shipping costs on the right when choosing another delivery type
function UpdateTransportOnDeliveryTypeChange() {
    // Choose another delivery type
    $('div#delivery-types input').click(function (event) {
        var strCountryCode = '';
        if ($('#selCountry').length) {
            strCountryCode = $('#selCountry').val();
        } else if ($('#selTransportCountry').length) {
            strCountryCode = $('#selTransportCountry').val();
        }

        // Update shipping costs
        $.post(strAjaxPath + 'cart/update-transport.php', 'intDeliveryType=' + $(this).val() + '&strCountryCode=' + strCountryCode, function (objData) {
            // Update extra transport price and show/hide
            if (objData.intTransportExtra > 0) {
                $('tr#cart-transport-extra').removeClass('d-none');
            } else {
                $('tr#cart-transport-extra').addClass('d-none');
            }
            $('tr#cart-transport-extra td span#cart-transport-extra-price').html(objData.strTransportExtra);
            $('tr#cart-transport-extra td span#cart-transport-extra-label').html(objData.strTransportExtraLabel);
            // Update cart totals
            $('span#cart-price-total').html(objData.strTotal);
            $('span#cart-total-price').html(objData.strTotal);
        }, 'json');
    });
}

// Update shipping costs itself
function UpdateShippingCosts(strParams) {
    // Update shipping costs
    $.post(strAjaxPath + 'cart/update-transport.php', strParams, function (objData) {
        // Shipping costs
        console.log(objData);
        if (objData.transport > 0) {
            $('tr#cart-transport span#cart-transport-price').html(objData.strTransport);
        } else {
            $('tr#cart-transport span#cart-transport-price').html(strLabelFree);
        }

        // Delivery type
        if (objData.intTransportExtra > 0) {
            $('tr#cart-transport-extra').show();
        } else {
            $('tr#cart-transport-extra').hide();
        }
        $('tr#cart-transport-extra td span#cart-transport-extra-price').html(objData.strTransportExtra);
        $('tr#cart-transport-extra td span#cart-transport-extra-label').html(objData.strTransportExtraLabel);
        // Update totals
        $('span#cart-total-price').html(objData.strTotal);
        $('span#cart-price-total').html(objData.strTotalAfterDiscount);
    }, 'json');
}

window.UpdateShippingCosts = UpdateShippingCosts;
window.UpdateTransportOnDeliveryTypeChange = UpdateTransportOnDeliveryTypeChange;
window.UpdateTransportOnCountryChange = UpdateTransportOnCountryChange;