import "../cart.js";
/*
 *
 *  FUNCTIONS
 *  contains basic js fot the entire website
 *  this file is always loaded on the bottom of the page
 *  !! Created by Ilse on 05/08/2016
 *
 */
$(document).ready(function () {

    //-- When clicked on the round button on the bottom of the hero-section on each page
    const slideDownBtn = document.querySelector("[data-slide='down']");
    if (slideDownBtn) {
        slideDownBtn.addEventListener("click", (event) => {
            const buttonPosition = event.currentTarget.getBoundingClientRect();
            let targetPosition = buttonPosition.top + window.scrollY + buttonPosition.height;
            const headerHeight = document.querySelector(".fixed-header-bar").offsetHeight;

            //-- Check for mobile screens && compensate for fixed header on mobile-screens
            if (window.innerWidth < 768) {
                targetPosition = targetPosition - headerHeight;
            }

            window.scrollTo({
                behavior: "smooth",
                top: targetPosition,
            });
        });
    }

    //-- Add to cart from bestseller-section on home-page & dynamic pagebuilder pages
    AddToCart($("form.form-add-to-cart button.btn-basket"), false, true);

    // Check if cookie popup should be shown
    if ($('#link-cookie-popup').length) {
        CookiePopup();

        // Open cookie popup on default
        $('#link-cookie-popup').click();
    } else {
        // check if popup spotlight should be shown
        if($('#linkPopupSpotlight').length) {
            if(sessionStorage.getItem('popupSpotlight') === null) {
                $('#linkPopupSpotlight').click();
                sessionStorage.setItem('popupSpotlight', 1);
            }
        }
    }

    // Click on link in video's that are disabled because social cookies are disabled
    $('a.allow-social-cookies, a.get-cookie-settings').click(function () {
        // Get popup
        $.post(strAjaxPath + 'get-cookie-popup.php', '', function (data) {
            // Show popup
            $('footer').after(data);
            CookiePopup();
            $('#link-cookie-popup-settings').click();
        });

        return false;
    });

    // 1. TOUCH OR NO TOUCH
    //check between touch and not touch
    function is_touch_device() {
        return (('ontouchstart' in window)
            || (navigator.MaxTouchPoints > 0)
            || (navigator.msMaxTouchPoints > 0));
    }

    // 2. make every link with rel=external work as a target _blank link
    // used instead of inline target blank for validation reasons
    $('a[rel=\'external\']').click(function () {
        $(this).attr('target', '_blank');
    });
    // 3. Menu slide toggle
    // when clicked on the link give the body an extra classe
    // slidetoggle is made with css (structure.scss)
    $('.mobile-nav-toggle').on('click touchstart', function () {
        $('body').toggleClass('menu-open');
        $('.product-nav').toggleClass('d-none');
        return false;
    });


    if (is_touch_device()) {
        // if touch device no hover but click, only on devices larger then 768, smaller is link through
        if ($(window).width() >= 768) {
            $('div.mini-cart > a').on('click touchstart', function () {
                $('div.mini-cart').toggleClass('showsub');
                return false;
            });
        }
    }

    //get the head navigaiton to work on click on touch devices
    if ($(window).width() >= 992) {
        if (is_touch_device()) {
            //click on a headnavigation item
            $('nav.navigation ul li a.sublink').each(function () {
                $(this).on('click touchstart', function () {
                    if ($(this).parent().hasClass('hover')) {
                        var href = $(this).attr('href');
                        window.location.href = href;

                    } else {
                        $('nav.navigation ul li').removeClass('hover');
                        $(this).parent().addClass('hover');
                        return false;
                    }
                });
            });

            // When clicked outside of the li-items: remove every 'hover' class on the li-elements
            $('body').on('click touchstart', function (event) {
                if (!$(event.target).is('nav.navigation ul, nav.navigation ul li, nav.navigation ul li a')) {
                    $('nav.navigation ul, nav.navigation ul li').removeClass('hover');
                }
            });
        }
    }

    // Fancybox
    $('[data-fancybox], a.fancybox').fancybox({
        loop: false,
        protect: true,
        keyboard: true,
        toolbar: true,
        buttons: [
            'close'
        ],
        animationEffect: 'fade',
        animationDuration: 366,
        transitionEffect: 'slide',
        clickContent: function (current, event) {
            return current.type === 'image' ? 'zoom' : false;
        }
    });


});

// Limit counter (for limit on textarea)
// Used for: the giftcard
function UpdateCountdown(objMessage, objCountdown, intLimit, strLabel) {
    var intRemaining = intLimit - objMessage.val().length;
    // Make sure you can't go over the max. number of characters
    if (intRemaining <= 0) {
        objMessage.val(objMessage.val().substr(0, intLimit));
        intRemaining = 0;
    }

    // Notification
    objCountdown.text(strLabel.replace('[NUMBER]', intRemaining));
}

// Html entity decode (for special characters)
// Used for: Ajax response
function html_entity_decode(str) {
    try {
        var tarea = document.createElement('textarea');
        tarea.innerHTML = str;
        return tarea.value;
        tarea.parentNode.removeChild(tarea);
    } catch (e) {
        //for IE add<div id="htmlconverter" style="display:none;"></div> to the page
        document.getElementById('htmlconverter').innerHTML = '<textarea id="innerConverter">' + str + '</textarea>';
        var content = document.getElementById('innerConverter').value;
        document.getElementById('htmlconverter').innerHTML = '';
        return content;
    }
}

// local and session storage
// Used for: the wishlist
function GetLocalStorage(strName) {
    var strItem = '';

    if (typeof (Storage) !== 'undefined' && localStorage[strName]) {
        strItem = localStorage.getItem(strName);
    }

    return strItem;
}

function SetLocalStorage(strName, strValue) {
    if (typeof (Storage) !== 'undefined') {
        localStorage.setItem(strName, strValue);
    }
}

function GetSessionStorage(strName) {
    var strItem = '';

    if (typeof (Storage) !== 'undefined' && sessionStorage[strName]) {
        strItem = sessionStorage.getItem(strName);
    }

    return strItem;
}

function SetSessionStorage(strName, strValue) {
    if (typeof (Storage) !== 'undefined') {
        sessionStorage.setItem(strName, strValue);
    }
}

// Cookie popup
function CookiePopup() {
    // Allow cookies popup
    $('#link-cookie-popup').fancybox({
        'modal': true,
        clickOutside: false,
        dblclickOutside: false,
        smallBtn: false,
    });

    // Cookie settings popup
    $('#link-cookie-popup-settings').fancybox({
        'modal': true,
        clickOutside: false,
        dblclickOutside: false,
        smallBtn: false,
        buttons: []
    });

    // Click on button 'Allow all cookies' in cookie popup
    $('#btnCookieAllowAll').click(function () {
        // Allow all cookies in ajax
        $.post(strAjaxPath + 'cookies.php', 'all=1', function () {
            // Reload page
            document.location.reload();
        }, 'json');
        return false;
    });

    // Click on button 'Settings' in cookie popup
    $('#btnCookieSettings').click(function () {
        // Open new popup with cookie settings
        $('#link-cookie-popup-settings').click();
    });

    // Click on save button in cookie settings popup
    $('#btnCookiebarConfirm').click(function () {
        // Check which cookies were allowed by the customer
        var strParams = '';
        if ($('#chkCookiesAnalytics').is(':checked')) {
            strParams += 'analytics=1';
        }
        if ($('#chkCookiesSocial').is(':checked')) {
            strParams += (strParams != '' ? '&' : '') + 'social=1';
        }
        // Allow these cookies in ajax
        $.post(strAjaxPath + 'cookies.php', strParams, function () {
            // Reload page
            document.location.reload();
        });
        return false;
    });
}

window.SetSessionStorage = SetSessionStorage;
window.GetSessionStorage = GetSessionStorage;
window.SetLocalStorage = SetLocalStorage;
window.GetLocalStorage = GetLocalStorage;
window.html_entity_decode = html_entity_decode;
window.UpdateCountdown = UpdateCountdown;