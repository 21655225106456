/**
 *
 *    DISCOUNT IN CART
 *    Contains all functions needed to add or remove a discount code to or from the cart
 *
 */

// Add discount code
function AddDiscountCode() {
    // After entering a discount code
    $('#txtDiscountCode').blur(function () {
        // Validate discount code
        // if ($('#txtDiscountCode').val()) {
        $('#discountcode-error').remove();
        $('#txtDiscountCode').removeClass('is-invalid');

        var strParams = 'strAction=add&intAddressNo=' + intAddressNo + '&strDiscountCode=' + $('#txtDiscountCode').val();
        $.post(strAjaxPath + 'cart/discount.php', strParams, function (arrObjecten) {
            if (arrObjecten.value == 0) {
                if (arrObjecten.popuplogin) {
                    // Show popup login (if gift card and not logged in)
                    var objALogin = $('<a href="' + arrObjecten.message + '" class="popuplogin"></a>');
                    $('form#frmDiscount').append(objALogin);
                    // Open 'Product added' popup
                    objALogin.fancybox({
                        helpers: {
                            overlay: {
                                locked: false
                            }
                        },
                        autoSize: true,
                        iframe: {
                            preload: true,

                            css: {
                                width: '800px'
                            }
                        },
                        transitionIn: 'none',
                        transitionOut: 'none',
                        type: 'iframe'
                    });
                    objALogin.click();
                } else {
                    // Show error
                    $('#txtDiscountCode').addClass('is-invalid');
                    var error = $('<label id=\'discountcode-error\' class=\'d-flex invalid-feedback\' for="txtDiscountCode">' + arrObjecten.message + '</label>');
                    $('form#frmDiscount').after(error);
                }
            } else if (arrObjecten.value == 1) {
                // Correct code => reload page
                document.location.reload();
            }
        }, 'json');
        // }
    });

    // Also add discount code when clicking enter in input field
    $('#txtDiscountCode').keypress(function (e) {
        if (e.which == 13) {
            $(this).blur();
            return false;
        }
    });
}

// Remove discount code
function RemoveDiscountCode() {

    // Click on 'remove discount code'
    $('a[id^=remove-discount]').click(function () {

        // Remove discount code
        var arrDiscountno = $(this).attr('id').split('-');
        var strParams = 'strAction=delete&intDiscountNo=' + arrDiscountno[2];
        $.post(strAjaxPath + 'cart/discount.php', strParams, function (arrObjecten) {

            $('div.discountcode-error').remove();
            if (arrObjecten.value == 0) {
                // Show error
                var objDivBoxError = $('<div class=\'discountcode-error\'></div>');
                objDivBoxError.append('<p>' + arrObjecten.message + '</p>');
                $('form#frmDiscount').append(objDivBoxError);

            } else if (arrObjecten.value == 1) {

                // Right code => reload page
                document.location.reload();

            }

        }, 'json');

        return false;
    });
}

window.RemoveDiscountCode = RemoveDiscountCode;
window.AddDiscountCode = AddDiscountCode;