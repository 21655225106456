// Autocomplete when you enter something in search box header
$(document).ready(function () {
    // Autocomplete when you enter something in search box header
    var Timer;

    $(".search input#txtSearch").keyup(function (e) {
        clearTimeout(Timer);

        if ($(this).val().length >= 3 && e.which != 13) {
            strCurrentSearch = $(this).val();

            Timer = setTimeout(
                (function (opts) {
                    return function () {
                        AutoSearch(opts);
                    }
                })($(this).val()), 400);
        } else {
            $("header div.suggestion").addClass("d-none");
        }
    });

});

// Hide search suggestion when you don't click on it
$(document).mouseup(function (e) {
    var container = $("header div.suggestion");
    if (!container.is(e.target)
        && container.has(e.target).length === 0) {
        container.addClass("d-none");
    }
});

// Autosuggestion search
function AutoSearch(strSearch) {
    if (strSearch == undefined)
        strSearch = "";
    var strTempSearch = $("header input#txtSearch").val();
    $("header div.loader").removeClass("d-none");
    if (strSearch == strCurrentSearch) {
        // Get autocomplete search
        $.post(strAjaxPath + "search-suggest.php", $("form.search").serialize(), function (data) {
            if (strTempSearch == $("header input#txtSearch").val()) {
                if (data != "") {
                    $("header div.loader").addClass("d-none");
                    $("header div.suggestion").html(data);
                    $("header div.suggestion").removeClass("d-none");
                } else {
                    $("header div.suggestion").addClass("d-none");
                    $("header div.loader").addClass("d-none");
                }

                // Clicking on a link after 'Bedoelde u' in suggestion
                $('header div.suggestion div.suggest a.suggest').click(function () {
                    // If the suggestion is the name/description/... from a product => search on that suggestion
                    // (If the suggestion is a categoryname, then there's a href => go to that href)
                    if ($(this).attr('href') == "#") {
                        $('header input#txtSearch').val($(this).find('strong').html());
                        $('header form.search').submit();
                    }
                });
            }

            // Link 'See all results' in search autocomplete header
            $('header div.suggestion a.view-all').click(function () {
                $(this).closest('form').submit();
                return false;
            });
        }, "html");
    }
}