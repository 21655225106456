/**
 *
 *    DD TO / UPDATE / DELETE FROM CART
 *    Contains all functions needed to add, update or delete a product from the cart
 *
 */

// Add item to shopping cart with check on stock
function AddToCart(objButton, blnShowAddToCartQtyField, blnRemoveDecimals) {
    $(objButton).click(function () {

        var objButton = $(this);
        var objForm = $(this).parents('form:first');
        // When there has to be checked on stock
        if (typeof WebsiteSettingBlnCheckStockAddCart !== "undefined" && typeof WebsiteSettingBlnUseStock !== "undefined" && WebsiteSettingBlnCheckStockAddCart && WebsiteSettingBlnUseStock) {
            // Check whether there's enough stock of this product

            // Hide previous error (if needed)
            $(objForm).find('div.stock-error').remove();

            //var objButton = $(this);
            if ($(objForm).find('input[name=\'txtQuantity\']').length) {
                var intQuantity = $(objForm).find('input[name=\'txtQuantity\']').val();
            } else {
                var intQuantity = $(objForm).find('input[name=\'hdnQuantity\']').val();
            }
            // Check stock from product
            $.post(strAjaxPath + 'cart/check-stock.php', 'intArticleNo=' + $(objForm).find('input[name=\'hdnArticleNo\']').val() + '&intPriceNo=' + $(objForm).find('input[name=\'hdnPriceNo\']').val() + '&intQuantity=' + intQuantity, function (data) {
                if (!objButton.hasClass('just-added')) {
                    // Add check so the user doesn't click quickly twice on the button
                    objButton.addClass('just-added');
                    setTimeout(function () {
                        objButton.removeClass('just-added');
                    }, 2000);

                    if (data.value == 0) {
                        // Not enough stock => show error
                        var objDivStockError = $('<div class=\'text-danger show\'></div>');
                        objDivStockError.html(data.message);
                        $(objForm).append(objDivStockError);
                    } else {
                        // Enough stock => add product(s) to cart
                        AddProductToCart(objForm, objButton, blnShowAddToCartQtyField, true);
                    }
                }
            });
        } else {
            // No check on stock => add product to cart
            AddProductToCart(objForm, objButton, blnShowAddToCartQtyField, true);
        }

        return false;
    });
}

// Add item to shopping cart
function AddProductToCart(objForm, objButton, blnShowAddToCartQtyField, blnRemoveDecimals, strParams) {

    // Initialise if the quantity has to be rounded on 0 decimals (after the comma)
    if (typeof blnRemoveDecimals == 'undefined' || blnRemoveDecimals == '') {
        blnRemoveDecimals = true;
    }

    if (typeof strParams == 'undefined') {
        strParams = '';
    }

    // There is no quantity field or it doesn't have to be checked
    var blnAddZero = false;
    // Check on quantity = 0 (if needed)
    if (blnShowAddToCartQtyField) {
        // Hide previous error (if needed)
        $(objForm).find('div.quantity-error').remove();
        var blnAddZero = true;
        $(objForm).find('input[name=\'txtQuantity\']').each(function () {
            if ($(this).val() > 0) {
                blnAddZero = false;
            }
        });
        if (blnAddZero) {
            // Show error when they try to add 0 items
            var objDivQuantityError = $('<div class=\'alert error quantity-error\'>' + lblOrderAtLeastOne + '</div>');
            $(objForm).append(objDivQuantityError);
        }
    } else {
        // There is no quantity field or it doesn't have to be checked
        var blnAddZero = false;
    }

    if (!blnAddZero) {
        // Ensure that there are no decimal numbers added (if necessary)
        if (blnShowAddToCartQtyField && blnRemoveDecimals) {
            $(objButton).parent().find('input[name=\'txtQuantity\']').each(function () {
                $(this).val(Math.ceil($(this).val()));
            });
        }

        // Check if no params were given with the function
        if (strParams == '') {
            // Make strParams
            if ($(objForm).find('input[name=\'txtQuantity\']').length) {
                intQuantity = $(objForm).find('input[name=\'txtQuantity\']').val();
            } else {
                intQuantity = $(objForm).find('input[name=\'hdnQuantity\']').val();
            }
            strParams = 'intArticleNo=' + $(objForm).find('input[name=\'hdnArticleNo\']').val() + '&intPriceNo=' + $(objForm).find('input[name=\'hdnPriceNo\']').val() + '&intQuantity=' + intQuantity;
        }

        // Add items to cart
        $.post(strAjaxPath + 'cart/cart.php', strParams, function (data) {
            // Product was added to cart
            // Show popup
            var strHrefPopupProductAdded = $(objForm).find('input[name=\'hdnHrefPopupProductAdded\']').val();
            var objA = $('<a data-fancybox=\'iframe\' data-src=\'' + strHrefPopupProductAdded + '\'  data-type=\'iframe\' href=\'javascript:;\'></a>');
            objForm.append(objA);
            // Open 'Product added' popup
            objA.fancybox({
                autoSize: true,
                iframe: {
                    preload: true,

                    css: {
                        width: '800px',
                    },
                },
                afterClose: function () {
                    objA.remove();
                },
            });

            objA.click();

            // Update mini cart in pagehead
            UpdateMiniCart();

        }, 'json');
    }
}

// Update mini cart in pagehead
function UpdateMiniCart() {
    $.post(strAjaxPath + 'cart/mini-cart.php', '', function (data) {
        //$("div#mini-cart-container").html(data);
        $('div#mini-cart-container').replaceWith(data);
    }, 'html');
}

// Update quantity in cart
function CartUpdateCart(objInput, blnCheckStock) {
    if (typeof blnCheckStock === 'undefined') {
        blnCheckStock = false;
    }

    // Ensure that there are no decimal numbers added (if necessary)
    objInput.val(Math.ceil(objInput.val()));
    var objForm = objInput.parents('form:first');

    // Get articleno and priceno from id from input field
    var strQtyId = objInput.attr('id');
    var arrQtyId = strQtyId.split('_');
    var intArticleNo = arrQtyId[1];
    var intPriceNo = arrQtyId[2];
    var intQuantity = objInput.val();

    if (blnCheckStock) {
        // Check stock (if needed)

        // Hide previous error (if needed)
        $('form#frmCartTable').find('div.alert-danger').remove();

        var strSelQtyId = strQtyId.replace('qty', 'selQty');
        // var strOldQtyId = strQtyId.replace("qty", "hdnOldQty");

        if (intArticleNo > 0 && intPriceNo > 0) {
            // Check stock via ajax
            $.post(strAjaxPath + 'cart/check-stock.php', 'intArticleNo=' + intArticleNo + '&intPriceNo=' + intPriceNo + '&intQuantity=' + intQuantity + '&strType=cart', function (data) {
                if (data.value == 1) {
                    // To remember the original quantity
                    // $("#" + strOldQtyId).val(objInput.val());

                    // Update cart
                    UpdateCart(intArticleNo, intPriceNo, intQuantity);

                } else {
                    // Show error
                    // var objDivStockError = $("<div class='font-small text-color-danger mt-2 bg-color-light text-left stock-error'></div>");
                    // objDivStockError.html(data.message);
                    // objInput.parent().append(objDivStockError);

                    var objDivStockError2 = $('<div class=\'alert alert-danger mb-2\'></div>');
                    objDivStockError2.html(data.messagecart);
                    $('form#frmCartTable').prepend(objDivStockError2);

                    // Change quantity to quantity on stock
                    $('#' + strQtyId).val(data.stock);
                    $('#' + strSelQtyId).val(data.stock);
                    if (data.stock == 0) {
                        $('#' + strQtyId).closest('tr').addClass('d-none');
                    }

                    // Update cart
                    UpdateCart(intArticleNo, intPriceNo, data.stock);

                    // Reset value to old value
                    // $("#" + strQtyId).val($("#" + strOldQtyId).val());
                    // $("#" + strSelQtyId).val($("#" + strOldQtyId).val());
                }

                return false;
            });
        }
    } else {
        UpdateCart(intArticleNo, intPriceNo, intQuantity);
    }
}

// Update cart (in cart-table) (the updating itself, no checks)
function UpdateCart(intArticleNo, intPriceNo, intQuantity) {
    if (intArticleNo > 0 && intPriceNo > 0 && intQuantity >= 0) {
        $.post(strAjaxPath + 'cart/cart.php', 'intArticleNo=' + intArticleNo + '&intPriceNo=' + intPriceNo + '&intQuantity=' + intQuantity + '&strAction=update', function (arrData) {

            if (WebsiteSettingBlnShowVatSeparatelyInCart) {
                // VAT is a seperate rule in cart-total table

                // Update subtotal
                $('span#cart-subtotal-price').html(arrData['cartprice']['subtotalexclvat']);

                // Update vat total
                $('span#cart-vat-price').html(arrData['cartprice']['vat']);

                // Update transport
                $('span#cart-transport-price').html(arrData['cartprice']['transportexcl']);
            } else {
                // All totals/subtotals are shown incl. VAT in cart

                // Update subtotal
                $('span#cart-subtotal-price').html(arrData['cartprice']['subtotalinclvat']);

                // Update transport
                $('span#cart-transport-price').html(arrData['cartprice']['transport']);
            }

            // Remove all discount lines
            $('div#cart-discount').remove();
            // Check if there are discounts
            if (arrData.discount.length > 0) {
                // Add discount lines
                $('div#cart-subtotal').after(arrData.discount);
            }

            $('div#cart-discount-added').remove();
            if (arrData.discountAdded.length > 0) {
                // Add discount lines
                $('form#frmDiscount').after(arrData.discountAdded);
            }

            // Update total
            $('span#cart-total-price').html(arrData['cartprice']['total']);

            // Remove all giftcard lines
            $('div#cart-giftcard').html('');
            if (arrData.giftcard.length > 0) {
                $('div#cart-giftcard').html(arrData.giftcard);

                // Update total after discount
                $('div#cart-total span#cart-price-total').html(arrData['cartprice']['total_after_discount']);
            }

            // Update subtotals per product line
            $.each(arrData['cart'], function (intKey, arrCartLine) {
                if (WebsiteSettingBlnShowVatSeparatelyInCart) {
                    // VAT is a seperate rule in cart-total table

                    // Update subtotal for product line
                    $('span#subtotal_' + arrCartLine['articleno'] + '_' + arrCartLine['priceno']).html(arrCartLine['price'][arrCartLine['priceno']]['subtotalexcl']);
                } else {
                    // All totals/subtotals are shown incl. VAT in cart

                    // Update subtotal for product line
                    $('span#subtotal_' + arrCartLine['articleno'] + '_' + arrCartLine['priceno']).html(arrCartLine['price'][arrCartLine['priceno']]['subtotal']);
                }
            });

        }, 'json');
    }
}

// Remove item from cart
function RemoveFromCart(intCartKey) {
    strParams = 'intCartKey=' + intCartKey + '&strAction=delete';
    $.post(strAjaxPath + 'cart/cart.php', strParams, function (data) {
        // Reload page
        document.location.reload();
    }, 'json');
}


window.AddToCart = AddToCart;
window.UpdateMiniCart = UpdateMiniCart;
window.RemoveFromCart = RemoveFromCart;
window.UpdateCart = UpdateCart;
window.CartUpdateCart = CartUpdateCart;
window.AddProductToCart = AddProductToCart;
