import jquery from 'jquery';
import 'bootstrap';

import '@fancyapps/fancybox';

import './core/plugins/jquery.menu';
import './core/functions';
import './elements/search';
//import './elements/dropdown-menu';

window.jQuery = jquery;
window.$ = jquery;
window.jquery = jquery;


