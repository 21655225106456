/**
 *
 *    GIFT CARD IN CART
 *    Contains all functions needed to add or remove a giftcard code to or from the cart
 *
 */

// Add giftcard code
function AddGiftCardCode() {

    // If enter in gift card code field
    $('input#txtGiftcardCode').keypress(function (e) {
        if (e.which == 13) {
            $(this).blur();
            return false;
        }
    });

    // After entering a gift card code
    $('input#txtGiftcardCode').blur(function () {
        // Validate
        if ($(this).val() != '') {
            // Check if it's a valid gift card code and if valid, save the gift card code in session
            var strParams = 'strAction=add&intAddressNo=' + intAddressNo + '&strGiftCardCode=' + $(this).val();
            $.post(strAjaxPath + 'cart/giftcard.php', strParams, function (arrObjecten) {
                // Remove previous errors
                $('#gift-cart-error').remove();
                $('#txtGiftcardCode').removeClass('is-invalid');

                if (arrObjecten.value == 0) {
                    // Error
                    $('#txtGiftcardCode').addClass('is-invalid');
                    var objLabel = $('<label id="gift-cart-error" class="d-flex invalid-feedback" for="txtGiftcardCode">' + html_entity_decode(arrObjecten.message) + '</label>');
                    $('#gift-card-form').after(objLabel);

                } else if (arrObjecten.value == 1) {
                    // Correct code => reload page
                    document.location.reload();
                }
            }, 'json');
        }
    });

    // When clicking on button 'Add' (and not filled in the gift card code)
    $('#gift-card-form #btnAddGiftCard').click(function () {
        if ($('input#txtGiftcardCode').val() == '') {
            $('#gift-cart-error').remove();
            $('#txtGiftcardCode').removeClass('is-invalid');

            if (typeof strLabelGiftCardNotValid !== 'undefined') {
                $('#txtGiftcardCode').addClass('is-invalid');
                var objLabel = $('<label id="gift-cart-error" class="d-flex invalid-feedback" for="txtGiftcardCode">' + strLabelGiftCardNotValid + '</label>');
                $('#gift-card-form').after(objLabel);
            }
        }
    });
}

// Remove giftcard code
function RemoveGiftCardCode() {
    $('a.remove-giftcard').click(function () {
        var arrGiftcardno = $(this).attr('id').split('-');
        var strParams = 'strAction=delete&intGiftCardNo=' + arrGiftcardno[2];
        $.post(strAjaxPath + 'cart/giftcard.php', strParams, function (arrObjecten) {
            // Remove previous errors
            $('div#gift-card-error').remove();
            if (arrObjecten.value == 0) {
                // Error
                var objDivError = $('<div class=\'alert error\' id=\'gift-card-error\'></div>');
                objDivError.text(arrObjecten.message);
                $('div#giftcard-added table').after(objDivError);
            } else if (arrObjecten.value == 1) {
                // Right code => reload page
                document.location.reload();
            }
        }, 'json');

        return false;
    });
}

window.RemoveGiftCardCode = RemoveGiftCardCode;
window.AddGiftCardCode = AddGiftCardCode;
